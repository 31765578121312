import {
  Environment,
  Network,
  RecordSource,
  Store,
  Observable,
} from 'relay-runtime';
import type {
  RequestParameters,
  Variables,
  GraphQLResponse,
  Observer,
} from 'relay-runtime';

import { parse } from 'graphql';

import type { DocumentNode } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import createRelaySubscriptionHandler from 'graphql-ruby-client/subscriptions/createRelaySubscriptionHandler';

const GRAPHQL_ENDPOINT = '/graphql';

const uploadLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
  headers: {
    'X-CSRF-Token': AUTH_TOKEN,
    Accept:
      'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8',
    'Content-Type': 'application/json',
  },
});

interface OperationCompat {
  query: DocumentNode;
  variables: Variables;
  extensions: Record<string, unknown>;
  getContext: () => Record<string, unknown> | undefined;
  setContext: (
    newContext: Record<string, unknown>
  ) => Record<string, unknown> | undefined;
}

const fetchFn = (
  operation: RequestParameters,
  variables: Variables
  // cacheConfig: CacheConfig,
  // uploadables?: UploadableMap | null
): Observable<GraphQLResponse> => {
  return Observable.create((observer: Observer<GraphQLResponse>) => {
    {
      const { text: query } = operation;
      const context = {};

      if (query === null) {
        observer.error?.(new Error('Query is null'));
        return;
      }

      if (!navigator.onLine) {
        observer.error?.(new Error('There is no internet access'));
        return;
      }

      if (uploadLink === null) {
        observer.error?.(new Error('uploadLink is null'));
        return;
      }
      // Create an object compatible with the Operation type
      const operationCompat: OperationCompat = {
        query: parse(query),
        variables: variables,
        extensions: {},
        getContext: () => (context ? { ...context } : {}),
        setContext: (newContext: Record<string, unknown>) =>
          context ? { ...context, ...newContext } : { ...newContext },
      };

      // @ts-expect-error - The Relay and Apollo types differ
      uploadLink.request(operationCompat).subscribe({
        next: (response) => {
          if (observer.next) {
            observer.next(response as GraphQLResponse);
          }
        },
        error: (err) => {
          if (err.statusCode === 401) {
            window.location.href = '/login';
          } else {
            if (observer.error) observer.error(err);
          }
        },
        complete: () => {
          if (observer.complete) observer.complete();
        },
      });
    }
  });
};

const subscriptionFn = createRelaySubscriptionHandler({
  channelName: 'GraphQLChannel',
  cable: window.App.cable,
});

function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchFn, subscriptionFn),
    store: new Store(new RecordSource()),
  });
}

export const RelayEnvironment = createRelayEnvironment();

// Simpler Fetch function that works with Relay But doesn't support file uploads. Try this if there are issues with the above.

// const fetchFn: FetchFunction = async (request, variables) => {
//   const resp = await fetch(HTTP_ENDPOINT, {
//     method: "POST",
//     headers: {
//       Accept:
//         "application/graphql-response+json; charset=utf-8, application/json; charset=utf-8",
//       "Content-Type": "application/json",
//       "X-CSRF-Token": AUTH_TOKEN,
//       // <-- Additional headers like 'Authorization' would go here
//     },
//     body: JSON.stringify({
//       query: request.text, // <-- The GraphQL document composed by Relay
//       variables,
//     }),
//   });

//   return await resp.json();
// };
